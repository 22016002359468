body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Trust Commander Banner */
.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding: 0 7.5%;
  position: relative
}

.footer_cookie_policy_description {
  margin: 0px;
}

@media(min-width: 688px) and (max-width:944px) {
  .container {
      padding:0 40px;
  }
}

@media(max-width: 688px) {
  .container {
    padding:0 20px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}