.mapboxgl-map {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}

.mapboxgl-ctrl-bottom-left {
  display: flex;
  z-index: 0 !important;
  width: 100%;
  margin-bottom: 22px;
}

.mapboxgl-ctrl-bottom-left > .mapboxgl-ctrl {
  margin: 0 0 56px 7.5% !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.mapboxgl-ctrl-fullscreen, .mapboxgl-ctrl-shrink {
  background-color: #fff;
  width: 48px !important;
  height: 32px !important;
  border-bottom: 3px solid #f0f0f0 !important;
}

.mapboxgl-ctrl-fullscreen:hover {
  background-color: '#f0f0f0',
}

.mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M10%202L11.5333%203.53333L9.60667%205.44667L10.5533%206.39333L12.4667%204.46667L14%206V2H10ZM2%206L3.53333%204.46667L5.44667%206.39333L6.39333%205.44667L4.46667%203.53333L6%202H2V6ZM6%2014L4.46667%2012.4667L6.39333%2010.5533L5.44667%209.60667L3.53333%2011.5333L2%2010V14H6ZM14%2010L12.4667%2011.5333L10.5533%209.60667L9.60667%2010.5533L11.5333%2012.4667L10%2014H14V10Z%22%20fill%3D%22%23343C3D%22%2F%3E%0A%3C%2Fsvg%3E%0A) !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M6.0013%206.00002V2.00002H4.66797V3.72669L2.60797%201.66669L1.66797%202.60669L3.72797%204.66669H2.0013V6.00002H6.0013ZM14.0013%206.00002V4.66669H12.2746L14.3346%202.60669L13.3946%201.66669L11.3346%203.72669V2.00002H10.0013V6.00002H14.0013ZM2.0013%2010V11.3334H3.72797L1.66797%2013.3934L2.60797%2014.3334L4.66797%2012.2734V14H6.0013V10H2.0013ZM10.0013%2010V14H11.3346V12.2734L13.3946%2014.3334L14.3346%2013.3934L12.2746%2011.3334H14.0013V10H10.0013Z%22%20fill%3D%22%23343C3D%22%2F%3E%0A%3C%2Fsvg%3E) !important;
}

.mapboxgl-ctrl-logo{
  display: none !important;
}