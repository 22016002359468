.mapboxgl-popup{
    z-index: 5
}

.mapboxgl-popup-content {
    padding: 0 !important;
}

.mapboxgl-popup-close-button {
    margin: 3px;
    font-size: 1.2rem;
}