.donut-hole:hover {
  fill: url(#clusterhovergradient) #eee;
}

.donut-segment {
  pointer-events: none;
}

.donut-ring {
  pointer-events: none;
}